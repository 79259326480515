import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as Ant from 'antd';
import ArticleItem from '../../Components/TwpaaArticleItem';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import qs from 'query-string';
import Theme from '../../Theme';
import {ArrowLeftShort} from '@styled-icons/bootstrap/ArrowLeftShort';

const pageSize = 12;
const collectionName = 'Article_Default';

function ProductList(props) {
  const [fetchResp, setFetchResp] = React.useState(null);
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const [searchName, setSearchName] = React.useState('');
  const [searchLabel, setSearchLabel] = React.useState('');
  const [tmpSearchName, setTmpSearchName] = React.useState('');
  const {action, id} = qs.parse(props.location.search);

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {labels: 'blog'};
      if (searchName) {
        extraQuery['title'] = {
          $regex: searchName,
        };
      }

      if (searchLabel) {
        extraQuery['labels'] = searchLabel;
      }

      setFetchResp(
        await JStorage.fetchDocuments(
          collectionName,
          extraQuery,
          ['-created'],
          paging,
          null,
          {token: null},
        ),
      );
    } catch (ex) {
      console.warn('REV_DBG', ex);
    }
    AppActions.setLoading(false);
  }, [paging, searchName, searchLabel]);

  React.useEffect(() => {
    async function fetchData() {
      if (action === 'detail') {
        AppActions.setLoading(true);
        try {
          setSelectedDocument(
            await JStorage.fetchOneDocument(collectionName, {id}),
          );
        } catch (ex) {
          console.warn('REV_DBG', ex);
        }
        AppActions.setLoading(false);
      } else {
        setSelectedDocument(null);
        fetchDocumentList();
      }
    }

    fetchData();
  }, [fetchDocumentList, action, id]);

  if (action === 'detail') {
    return (
      <Wrapper>
        <a
          href={'/info/articles/'}
          style={{display: 'flex', alignItems: 'center', marginBottom: 20}}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate('/info/articles/');
          }}>
          <ArrowLeftShort size={24} />
          <div style={{fontSize: 20}}>返回列表</div>
        </a>

        {selectedDocument && (
          <>
            <h1>{selectedDocument.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedDocument.raw_html?.enabled
                  ? selectedDocument.raw_html.html
                  : selectedDocument.html,
              }}
            />
          </>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1>文章專欄</h1>

      <div
        id="twpaa-article-search-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
          marginBottom: 30,
          gap: 10,
        }}>
        <Ant.Select
          style={{flex: '0 0 200px'}}
          value={searchLabel}
          onChange={setSearchLabel}>
          <Ant.Select.Option value={''}>全部</Ant.Select.Option>
          {['專利融資', '109年專利師節系列文章', '理事長的話'].map((it) => {
            return (
              <Ant.Select.Option value={it} key={it}>
                {it}
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>

        <Ant.Input
          value={tmpSearchName}
          onChange={(e) => setTmpSearchName(e.target.value)}
          placeholder="請輸入關鍵字"
          style={{flex: '2 0 280px', borderRadius: 100, height: 36}}
        />

        <div style={{display: 'flex'}}>
          <Ant.Button
            type="primary"
            onClick={() => {
              setSearchName(tmpSearchName);
            }}>
            搜尋
          </Ant.Button>
          <Ant.Button
            type="link"
            style={{marginLeft: 6}}
            onClick={() => {
              setTmpSearchName('');
              setSearchName('');
            }}>
            清除
          </Ant.Button>
        </div>
      </div>

      <div className="item-grid">
        {(fetchResp?.results || []).map((it) => {
          return (
            <ArticleItem
              key={it.id}
              article={it}
              onClick={() => {
                AppActions.navigate(
                  `/info/articles/?action=detail&id=${it.id}`,
                );
              }}
            />
          );
        })}
      </div>

      <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
        <Ant.Pagination
          size="small"
          total={fetchResp?.total || 0}
          showSizeChanger={false}
          current={paging.offset / pageSize + 1}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }

  .item-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }

  #twpaa-article-search-container .ant-select-selector {
    border-radius: 30px;
    height: 35px;
  }
`;

export default withPageEntry(ProductList);
