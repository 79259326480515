import React from 'react';
import styled from 'styled-components';

export default function ArticleItem(props) {
  const {article, onClick} = props;
  return (
    <Wrapper onClick={onClick}>
      <img
        className="cover"
        src={article.cover || '/images/site-logo.png'}
        alt="article"
      />
      <div className="content">
        <div>2024-01-02</div>
        <h3>{article.title}</h3>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #ddd;
  background: white;
  transition: all 200ms;
  overflow: hidden;
  cursor: pointer;

  & > img.cover {
    width: 100%;
    max-height: 220px;
    object-fit: cover;
    background: #ddd;
  }

  & > div.content {
    padding: 12px;
    background-color: white;
  }

  &:hover {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.3);
  }
`;
